// extracted by mini-css-extract-plugin
export var aArrow1 = "Zc";
export var aArrow2 = "_c";
export var aEmpty = "Pc";
export var aLeft = "dd";
export var aRight = "fd";
export var aTagAnnounce = "Wc";
export var aTagClose = "Vc";
export var aTagEntry = "Tc";
export var aTagFree = "Yc";
export var aTagLottery = "Xc";
export var aTagPayment = "Uc";
export var aText = "ad";
export var aTitle = "Qc";
export var aWrap = "cd";
export var animationMarquee = "hd";
export var infinityScrollLeft = "ed";
export var infinityScrollRight = "gd";
export var pItemBatch = "Rc";
export var pItemBatchAbs = "Sc";
export var pSlideItemList = "bd";